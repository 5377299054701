<template>
  <div class="-impact-topics-apply-filter">
    <div class="-impact-topics-apply-filter__apply-filter">
      <div class="-impact-topics-apply-filter__apply-filter--left">
        <div class="navigation">
          <i class="icon icon-lighthouse"></i>
          <h2>Impact Articles</h2>
          <ul class="nav-list">
            <li class="nav-item">
              <p class="active">
                Highlighted Current Events
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="-impact-topics-apply-filter__apply-filter--right">
        <the-pagination 
        @change-page="searchImpactArticle($event)"
        :pageNumber="currentPage"
        :totalPages="totalPages"
        :results="counts"
        ></the-pagination>
        <div class="buttons">
          <button
            @click="openLocationFilters()"
            :class="{ 'filter-active': visibleLocationFilter }"
            class="filter-btn filter-apply-btn"
          >
            <svg
              width="18"
              height="16"
              viewBox="0 0 18 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00008 16C7.71675 16 7.47925 15.9042 7.28758 15.7125C7.09591 15.5208 7.00008 15.2833 7.00008 15V9L1.20008 1.6C0.95008 1.26667 0.91258 0.916667 1.08758 0.55C1.26258 0.183333 1.56675 0 2.00008 0H16.0001C16.4334 0 16.7376 0.183333 16.9126 0.55C17.0876 0.916667 17.0501 1.26667 16.8001 1.6L11.0001 9V15C11.0001 15.2833 10.9042 15.5208 10.7126 15.7125C10.5209 15.9042 10.2834 16 10.0001 16H8.00008ZM9.00008 8.3L13.9501 2H4.05008L9.00008 8.3Z"
              />
            </svg>

            <p>: 8 Filters</p>
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              xmlns="http://www.w3.org/2000/svg"
              class="arrow"
            >
              <path d="M6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z" />
            </svg>
          </button>
          <button @click="visibleWriteStoryModal" class="highlight-btn">
            <img src="@/assets/icons/add.svg" alt="icon" /> Highlight a Current Event
          </button>
        </div>
      </div>
    </div>
    <impact-location-filter
      v-if="visibleLocationFilter"
    ></impact-location-filter>
    <add-story-modal
      ref="story-modal"
      :action="action"
      :showModal="showWriteStoryModal"
      @handle-close="showWriteStoryModal = false"
    ></add-story-modal>
  </div>
</template>

<script>
import AddStoryModal from "../EngageComponents/WriteStoryModal.vue"
import ThePagination from "./CustomPagination.vue";
import ImpactLocationFilter from "./sourcesFilters/locationFilters/ImpactLocationFilters.vue";



export default {
  components: {
    AddStoryModal,
    ThePagination,
    ImpactLocationFilter,
  },
  data() {
    return {
      visibleLocationFilter: false,
      action: "",
      articlesPerPage: 9,
      showWriteStoryModal: false,
      currentPage: 1
    };
  },
  computed: {
    totalArticles(){
      return this.$store.getters['impact/getImpactTotalArticles'];
    },
    counts() {
      const result = this.$store.getters["impact/getImpactArticles"].results;
      if(result){
        return result.length;
      }
      return 0;
    },
    totalPages() {
      return Math.ceil(this.totalArticles / this.articlesPerPage);
    },
  },
  methods: {
    visibleWriteStoryModal(){
      this.action = 'add';
      this.showWriteStoryModal = true;
    },
    openLocationFilters() {
      this.visibleLocationFilter = !this.visibleLocationFilter;
    },
    async searchImpactArticle(page) {
      this.currentPage = page;
      const payload = {
        query: "",
        offset: (page - 1) * 9,
        limit: 9,
        author: ""
      };
      this.emitter.emit('searching-impact-articles', true);
      await this.$store.dispatch("impact/fetchImpactArticle", payload);
      this.emitter.emit('searching-impact-articles', false);
    },
  },
};
</script>

<style lang="scss">
.-impact-topics-apply-filter {
  position: sticky;
  z-index: 44;
  top: 0;
  background-color: $color-white;
  &__apply-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3.1rem 4rem;
    border-top: 1px solid $color-dark-grey-5;
    box-shadow: rgba(33, 35, 38, 0.24) 0px 10px 10px -10px;
    width: 100%;
    position: relative;
    z-index: 44;
    @include respond(laptop-small) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    &--left {
      .navigation {
        display: flex;
        align-items: center;
        width: 100%;
        h2 {
          font-size: 2.2rem;
          font-family: $font-primary-bold;
          font-weight: 900;
          line-height: normal;
          margin-bottom: 0;
          cursor: pointer;
          position: relative;
          @include respond(phone-x-small) {
            font-size: 1.4rem;
          }
          &::after {
            content: "";
            width: 4rem;
            height: 0.4rem;
            background-color: $color-primary;
            position: absolute;
            bottom: -0.9rem;
            left: 0;
          }
        }
        .icon {
          font-size: 2.4rem;
          margin-right: 0.8rem;
        }
        .nav-list {
          padding: 0rem 2rem;
          margin-left: 2rem;
          border-left: 2px solid rgb(169, 169, 169);
          margin-bottom: 0;
          display: flex;
          align-items: center;
          .nav-item {
            list-style: none;
            p {
              cursor: pointer;
              font-size: 1.4rem;
              font-family: $font-secondary;
              opacity: 0.8;
              color: $color-black;
              margin-right: 2.5rem;
              position: relative;
              line-height: normal;
              margin-bottom: 0;
              @include respond(phone-x-small) {
                font-size: 1.4rem;
              }
            }
            .active {
              font-family: $font-primary-medium;
              opacity: 1;
            }
          }
        }
      }
    }
    &--right {
      display: flex;
      align-items: center;
      margin-left: auto;
      @include respond(laptop-small) {
        margin-top: 2rem;
      }
      @include respond(phone-x-small) {
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
      }
      .pagination-container {
        @include respond(phone-x-small) {
          margin-top: 2rem;
          order: 2;
        }
        &__pagination {
          margin-top: 0;
          padding-right: 2.6rem;
          @include respond(laptop-small) {
            padding-right: 0;
          }
          .btn {
            font-size: 1.3rem;
            .icon-forward {
              font-size: 1.3rem;
              color: $color-black;
            }
            .prev {
              margin-right: 1rem;
              margin-left: 0;
              margin-bottom: 0.6rem;  
            }
            .next {
              margin-left: 1rem;
              margin-right: 0;
            }
          }
          .counter {
            font-size: 1.3rem;
          }
        }
      }
      .buttons {
        display: flex;
        align-items: center;
        @include respond(laptop-small) {
          order: 1;
        }
        .filter-btn {
          display: flex;
          align-items: center;
          padding: 0.7rem 2.4rem;
          border-radius: 100px !important;
          border: none;
          outline: none;
          background-color: #ebebeb;
          cursor: pointer;
          height: 4.4rem;
          margin: 0 2rem;
          @include respond(phone-x-small) {
            margin-left: 0;
          }
          p {
            color: $color-black;
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            margin-bottom: 0;
            margin: 0 1rem;
          }
          svg {
            fill: $color-black;
            width: 1.6rem;
          }
          img {
            width: 1.6rem;
          }
        }
        .filter-active {
          background-color: #0a1438;
          p {
            color: $color-white;
          }
          .arrow {
            transform: rotate(180deg);
            margin-bottom: 0.2rem;
          }
          svg {
            fill: $color-white;
          }
        }
        .highlight-btn {
          background-color: $color-primary;
          display: flex;
          align-items: center;
          padding: 0.7rem 2rem;
          border-radius: 1rem;
          border: none;
          outline: none;
          cursor: pointer;
          height: 4.4rem;
          color: $color-white;
          font-size: 1.6rem;
          font-family: $font-primary-medium;
          @include respond(phone-x-small) {
            font-size: 1.4rem;
          }
          img {
            margin-right: 1.4rem;
            width: 1.3rem;
          }
        }
      }
    }
  }
}
</style>
