<template>
  <a-modal
    v-model:visible="showWriteStoryModal"
    :footer="null"
    @cancel="closeModal"
    wrapClassName="propose-story-impact"
    width="88rem"
  >
    <a-row>
      <a-col :sm="24" :xs="24" :md="12" :lg="12">
        <div class="propose-story-impact__left">
          <h2>
            Highlight An Issue to a Journalist and/or Topic Expert to Impact
            Positive Change
          </h2>
          <form action="">
            <p>Add a title *</p>
            <input data-cy="currentEventTitle" type="text" placeholder="Enter title here" v-model="title" />
            <span v-if="titleErr" class="fields-error"
              >This field is required</span
            >
            <p>Add location of current event</p>
            <TagifyAjax
              placeholder="Search location here"
              :options="locationOptions"
              @search-change="fetchLocations"
              @send-selections="fetchSelectedLocations"
              workingOn="locations"
              :isSubmitted="isSubmit"
              :removeFields="removeFields"
            ></TagifyAjax>
          </form>
          <div class="add-media">
            <div class="header">
              <p>Add Media *</p>
              <p class="file-size">Max 10 files</p>
            </div>
            <p class="error" v-if="mediaErr">Media is required</p>
            <div class="clearfix">
              <a-upload
                list-type="picture-card"
                :file-list="fileList"
                :before-upload="beforeUpload"
                @preview="handlePreview"
                @change="handleUpload"
                :multiple="true"
              >
                <div v-if="fileList.length < 10">
                  <i class="icon icon-add_plus"></i>
                </div>
              </a-upload>
              <p v-if="exceedLimit" class="error">
                You can upload max 10 files
              </p>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :sm="24" :xs="24" :md="12" :lg="12">
        <div class="propose-story-impact__right">
          <div class="story-description-main">
            <div class="story-description">
              <p>Add details about the current event *</p>
              <div class="textarea-main">
                <textarea
                  data-cy="currentEventDetails"
                  name=""
                  id=""
                  cols="30"
                  rows="14"
                  placeholder="Enter details here (Max 550 Words)"
                  v-model="description"
                ></textarea>
              </div>
              <span v-if="descriptionErr" class="fields-error"
                >This field is required</span
              >
            </div>
          </div>
          <p class="collaboration-heading">Send current event highlight</p>
          <h5 class="collaboration-description">
            (You can send this highlight to a specific journalist and/or subject
            matter expert directly, by entering their information below)
          </h5>
          <tagify-email
            placeholder="Invite Via Email"
            @fetchEmail="fetchedEmail"
            :isSubmitted="isSubmit"
            :nullify="removeFields"
          ></tagify-email>
          <br>
          <TagifyAjax
            placeholder="Search Journalists Here"
            :options="options"
            @search-change="fetchAllUsers"
            @send-selections="fetchSelectedJournalists"
            workingOn="journalists"
            :isSubmitted="isSubmit"
            :removeFields="removeFields"
          ></TagifyAjax>
          <a-button
            data-cy="currentEventSubmitButton"
            v-if="action == 'add'"
            :loading="loading"
            class="btn-primary"
            @click="submitHighlightedIssue"
          >
            Submit
          </a-button>
          <a-button
            v-if="action == 'edit'"
            :loading="loading"
            class="btn-primary"
            @click="updateHighlightedIssue"
          >
            Update
          </a-button>
        </div>
      </a-col>
    </a-row>
  </a-modal>
  <!-- <a-modal
    v-model:visible="storySubmitPopup"
    :footer="null"
    wrapClassName="story-submitted"
    width="44rem"
  >
    <div class="submited-message">
      <img class="icon icon-tick" src="../../assets/icons/check-circle.svg" />
      <h2>Current Event Submitted Successfully</h2>
    </div>
  </a-modal> -->

</template>

<script>
import TagifyAjax from "../PdbComponents/TagifyAjax.vue";
import TagifyEmail from "../PdbComponents/TagifyEmail.vue";

function getBase64(file, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(file);
}

export default {
  components: {
    TagifyEmail,
    TagifyAjax,
  },
  data() {
    return {
      showWriteStoryModal: false,
      storySubmitPopup: false,
      message: {},
      previewVisible: false,
      previewImage: "",
      fileList: [],
      exceedLimit: null,
      loading: false,
      onUsersRequest: false,
      onLocationsRequest: false,
      options: [],
      selectedJournalist: [],
      selectedLocation: [],
      title: "",
      description: "",
      emails: [],
      locations: [],
      descriptionErr: false,
      titleErr: false,
      mediaErr: false,
      locationOptions: [],
      isSubmit: false,
      removeFields:false,
    };
  },
  props: {
    action: String,
    showModal: Boolean,
    storyId: String,
    currentPage: Number,
  },
  emits: ["handle-close"],
  watch: {
    showModal() {
      this.showWriteStoryModal = this.showModal;
    },
  },
  methods: {
    async closeModal() {
      this.removeFields = await true
      this.removeFields = await false
      this.clearFields();
      this.$emit("handle-close", false);
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj, (imageUrl) => {
          this.previewImage = imageUrl;
        });
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    beforeUpload(file) {
      this.exceedLimit = false;
      this.file = file;
      return false;
    },
    handleUpload({ fileList }) {
      this.exceedLimit = false;
      if (fileList.length > 10) {
        this.exceedLimit = true;
        return;
      }

      this.fileList = fileList;
    },
    debounce(method, timer) {
      if (this.$_debounceTimer !== null) {
        clearTimeout(this.$_debounceTimer);
      }
      this.$_debounceTimer = setTimeout(() => {
        method();
      }, timer);
    },
    async fetchLocations(value) {
      try {
        // console.log("I AM FETCHING LOCATIONS",value);
        this.locationOptions = [];
        this.onLocationsRequest = true;
        // this.debounce(async () => {
        const response = await this.$store.dispatch(
          "impact/getLocations",
          value
        );
        if (response.status === 200) {
          response.data.results.map(async (location) => {
            if (location) {
              let locationList = {
                label: location.name,
                value: location.id,
              };
              this.locationOptions.push(locationList);
              await this.$store.dispatch(
                "tagify/fetchLocations",
                this.locationOptions
              );
              return locationList;
            }
          });
          this.onLocationsRequest = false;
        }
        // }, 1000);
      } catch (err) {
        console.log(err);
      }
    },
    async fetchAllUsers(value) {
      try {
        this.options = [];
        this.onUsersRequest = true;
        this.debounce(async () => {
          const payload = {
            full_name: value,
          };
          const response = await this.$store.dispatch(
            "profile/getJournalistsAndExperts",
            payload
          );
          if (response.status == 200) {
            response.data.map(async (val) => {
              if (val.profile) {
                let userList = {
                  label: val.profile.full_name,
                  value: val.id,
                  key: val.email,
                  icon: val.profile.profile_picture,
                };
                this.options.push(userList);
                // console.log("this.options", this.options);
                await this.$store.dispatch(
                  "tagify/fetchJournalists",
                  this.options
                );
                return userList;
              }
            });
            this.onUsersRequest = false;
          }
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    },
    clearFields() {
      this.fileList = [];
      this.title = "",
      this.description = "",
      this.emails = [],
      this.selectedJournalist = [],
      this.selectedLocation = [];
      this.mediaErr = false;
      this.titleErr = false;
      this.descriptionErr = false;
    },
    async submitHighlightedIssue() {
      if (this.title == "") {
        this.titleErr = true;
        return true;
      } else if (this.description == "") {
        this.descriptionErr = true;
        return true;
      } else if(this.fileList.length === 0){
        this.mediaErr = true;
        return true;
      }
      try {
        this.loading = true;
        const userEmails = Object.values(this.emails);
        const journalists = Object.values(this.selectedJournalist);
        const locations = Object.values(this.selectedLocation);
        const form = new FormData();
        locations.forEach((location) => {
          form.append("location", location);
        });
        this.fileList.forEach((val) => {
          form.append("media", val.originFileObj);
        });
        journalists.forEach((user) => {
          form.append("share_with_journalists", user);
        });
        userEmails.forEach((email) => {
          form.append("share_by_emails", email);
        });
        form.append("title", this.title);
        form.append("description", this.description);
        const response = await this.$store.dispatch(
          "impact/createImpactArticle",
          form
        );
        if (response.status === 201) {
          this.loading = false;
          this.closeModal();
          this.storySubmitPopup = true;
          this.message = {
            title: "Current Event Submitted Successfully",
            type: "success",
          };
          this.clearFields();
          this.isSubmit = await true;
          this.isSubmit = await false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async urlToObject(url) {
      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], "image.jpg", { type: blob.type });
      const data = {
        uid: "vc-upload-" + Date.now(),
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        name: file.name,
        size: file.size,
        type: file.type,
        originFileObj: file,
        thumbUrl: URL.createObjectURL(blob),
      };
      this.fileList.push(data);
    },
    selectStory(title, description, media, emails, collabrators) {
      media.forEach((val) => {
        this.urlToObject(val.media);
      });
      this.title = title;
      this.description = description;
      this.emails = emails;
      // collabrators.map((val) => {
      //   let userList = {
      //       label: val.full_name,
      //       value: val.id,
      //       key: val.email,
      //       icon: val.profile_picture,
      //     };
      // this.options.push(userList);
      // this.selectedJournalist.push(val.full_name);
      // return userList;
      // });

      // console.log(collabrators, emails);
    },
    async getHighlightedCurrentEvents() {
      try {
        const payload = {
          query: "",
          offset: (this.currentPage - 1) * 9,
          limit: 9,
          author: this.$route.params.id,
        };
        const response = await this.$store.dispatch(
          "impact/fetchImpactArticle",
          payload
        );
        if (response.status == 200) {
          this.loading = false;
          this.closeModal();
          this.storySubmitPopup = true;
          this.message = {
            title: "Current Event Updated Successfully",
            type: "success",
          };
          this.clearFields();
        }
      } catch (err) {
        console.log(err);
      }
    },
    async updateHighlightedIssue() {
      try {
        this.loading = true;
        const userEmails = this.emails ? Object.values(this.emails) : [];
        const journalists = Object.values(this.selectedJournalist);
        const locations = Object.values(this.selectedLocation);
        const form = new FormData();
        locations.forEach((location) => {
          form.append("place_id", location);
        });
        this.fileList.forEach((val) => {
          form.append("media", val.originFileObj);
        });
        journalists.forEach((user) => {
          form.append("share_with_journalists", user);
        });
        userEmails.forEach((email) => {
          form.append("share_by_emails", email);
        });
        form.append("title", this.title);
        form.append("description", this.description);
        form.append("story_id", this.storyId);
        const response = await this.$store.dispatch(
          "impact/updateImpactArticle",
          form
        );
        if (response.status === 200) {
          this.getHighlightedCurrentEvents();
          this.clearFields();
        }
      } catch (err) {
        console.log(err);
      }
    },
    fetchedEmail(val) {
      // console.log(val);
      this.emails = val;
    },
    fetchedSelection(val) {
      this.selectedLocation = val;
    },
    fetchedJournalists(val) {
      this.selectedJournalist = val;
    },
    fetchSelectedLocations(val) {
      this.selectedLocation = val;
    },
    fetchSelectedJournalists(val) {
      this.selectedJournalist = val;
    },
  },
};
</script>

<style lang="scss">
.story-modal {
  &__img {
    height: auto;
    .image {
      width: 100%;
    }
  }
  &__info {
    padding: 1rem 4rem;
    text-align: center;
    padding-bottom: 2rem;
    .story-btn {
      width: 90%;
      padding: 1.5rem 4rem;
      margin: 1rem 2rem;
      font-family: $font-secondary-bold;
    }
  }
  &__heading {
    font-family: $font-secondary-bold;
    font-size: 2.4rem;
    margin: 1rem;
  }
  &__text {
    font-family: $font-primary;
    font-size: 1.4rem;
    margin: 0 2rem 3rem;
  }
}

.propose-story-impact {
  .ant-modal-body {
    padding: 2.5rem 3rem !important;
    background-color: $color-light-grey-2;
    padding-bottom: 0.5rem !important;
  }
  .fields-error {
    font-family: $font-secondary;
    font-size: 1.6rem;
  }
  &__left {
    padding-right: 3rem;
    position: relative;
    @include respond(tab-land) {
      padding-right: 0;
    }
    .error {
      color: red;
    }
    .ant-upload-select-picture-card {
      height: 9rem !important;
      width: 7.5rem;
      .icon-add_plus {
        font-size: 2rem;
      }
    }
    .ant-upload-picture-card-wrapper {
      .ant-upload-list-picture-card-container {
        width: 68px;
        height: 86px;
        @include respond(tab-land) {
          width: 55px;
          height: 68px;
        }
        .ant-upload-list-item {
          width: 72px;
          height: 88px;
          @include respond(tab-land) {
            width: 56px;
            height: 69px;
          }
        }
      }
      .ant-upload-list-picture-card .ant-upload-list-item-actions {
        .anticon-delete {
          @include respond(tab-land) {
            width: 8px;
            font-size: 10px;
          }
        }
        .anticon-eye {
          @include respond(tab-land) {
            width: 8px;
            font-size: 10px;
          }
        }
      }
    }
    h2 {
      font-size: 2.4rem;
      font-family: $font-secondary-bold;
      line-height: 3.2rem;
      margin: 0;
      @include respond(tab-land) {
        padding-right: 3rem;
      }
    }
    form {
      margin: 3.5rem 0;
      p {
        font-family: $font-secondary-bold;
        font-size: 1.6rem;
        margin: 1rem 0 0.8rem;
      }
      .ant-select {
        .ant-select-selector {
          padding: 0.7rem 1rem;
          border: 1px solid rgba(0, 0, 0, 0.2) !important;
          border-radius: 0.5rem;
          @include respond(tab-port) {
            padding: 0 1rem;
          }
          .ant-select-selection-placeholder {
            left: 18px;
            font-family: $font-secondary;
            font-size: 1.6rem;
          }
        }
      }
      input {
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 0 1.5rem;
        height: 4.2rem;
        width: 100%;
        outline: none;
        font-family: $font-secondary;
        font-size: 1.6rem;
        margin-bottom: 0.5rem;
      }
      input::placeholder {
        opacity: 0.5;
      }
    }
    .add-media {
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        p {
          font-family: $font-secondary;
          font-size: 1.6rem;
          margin: 0;
        }
        .file-size {
          font-size: 1.2rem;
          opacity: 0.5;
        }
      }
    }
  }
  &__right {
    padding-top: 3rem;
    padding-left: 3rem;
    @include respond(tab-port) {
      padding-left: 0;
    }
    p {
      font-family: $font-secondary-bold;
      font-size: 1.6rem;
      margin-bottom: 0.8rem !important;
      line-height: 2rem;
    }
    .collaboration-heading {
      margin-top: 2rem;
    }
    .collaboration-description {
      font-family: $font-primary;
      font-size: 1.6rem;
      line-height: 2.2rem;
      margin-bottom: 2rem;
    }
    h3 {
      font-family: $font-secondary-bold;
      font-size: 1.6rem;
      margin-bottom: 0.8rem;
      line-height: 2.3rem;
    }
    .story-description {
      .textarea-main {
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 1.7rem;
        background-color: $color-white;
        padding-right: 0.7rem;
        margin-bottom: 1rem;
        textarea {
          width: 100%;
          border: none;
          outline: none;
          font-family: $font-secondary;
          font-size: 1.6rem;
          line-height: 2.2rem;
          padding-right: 1rem;
          resize: none;
        }
      }
      textarea::placeholder {
        opacity: 0.5;
      }
      textarea::-webkit-scrollbar-track {
        background-color: #03030309;
      }

      textarea::-webkit-scrollbar {
        width: 0.4rem;
        background-color: transparent;
        border-radius: 4rem;
      }

      textarea::-webkit-scrollbar-thumb {
        background-color: $color-primary;
        border-radius: 4rem;
      }
      
    }

    .story-description::before {
      content: "";
      position: absolute;
      height: 40rem;
      width: 0.24rem;
      background-color: rgba(128, 128, 128, 0.144);
      left: 0;
      @include respond(tab-port) {
        display: none;
      }
    }
    .ant-select {
      margin: 1rem 0;
      .ant-select-selector {
        border: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-radius: 0.5rem;
        padding: 0.7rem 1rem;
        @include respond(tab-port) {
          padding: 0 1rem;
        }
        .ant-select-selection-placeholder {
          left: 18px;
          font-family: $font-secondary;
          font-size: 1.6rem;
        }
      }
    }

    .btn-primary {
      width: 100%;
      height: 5rem;
      background-color: $color-primary;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-white;
      cursor: pointer;
      border: none;
      font-family: $font-secondary-bold;
      font-size: 1.6rem;
      margin: 2rem 0;
    }
  }
}
.fields-error {
  color: red;
}
.story-submitted {
  .ant-modal-body {
    padding: 3.5rem !important;
    margin-bottom: 2.5rem;
  }
  .submited-message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .icon {
      font-size: 2.5rem;
      color: green;
    }
    .icon-tick {
      filter: invert(36%) sepia(92%) saturate(1961%) hue-rotate(83deg)
        brightness(95%) contrast(94%);
    }
    h2 {
      font-size: 2.4rem;
      font-family: $font-secondary-bold;
      margin-top: 2rem;
      margin-bottom: 1.5rem;
      text-align: center;
    }
    p {
      font-family: $font-secondary;
      font-size: 1.6rem;
      margin: 0;
      text-align: center;
      line-height: 2.3rem;
      margin-bottom: 2rem;
    }
    button {
      width: 89%;
      height: 5rem;
      background-color: $color-primary;
      color: $color-white;
      cursor: pointer;
      border: none;
      font-family: $font-secondary-bold;
      font-size: 1.6rem;
    }
  }
}

</style>
