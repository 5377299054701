<template>
  <input class="" :placeholder="placeholder"/>
</template>

<script>
import Tagify from "@yaireo/tagify/dist/tagify.min.js";
import "@yaireo/tagify/dist/tagify.css";

export default {
  name: "App",
  props: {
    placeholder: String,
    isSubmitted: Boolean,
    nullify: {
      default: false,
      type: Boolean
    },
    prevTags:{
      default: ()=>([]),
      type:Array
    },
    module:{
      default: '',
      type: String
    }
  },
  data() {
    return {
      suggestions: [],
      selectedKeywords: [],
      tagify: null // Initialize tagify in data for better reactivity tracking,
    };
  },
  watch: {
    isSubmitted(val) {
      this.ifSubmitted = val;
      if (val) {
        this.tagify.removeAllTags();
        this.selectedKeywords=[]
      }
    },
    nullify(val) { 
      if (this.tagify) { 
        if (val){
          this.tagify.removeAllTags();
          this.selectedKeywords = []
        }
      }
    },
    prevTags: {
      handler(newVal) {
        if (this.tagify) {
          if (this.selectedKeywords.length === 0){
            this.tagify.addTags(newVal);
          }
          if (this.selectedKeywords.length > 0 && !this.selectedKeywords.every(keyword=>newVal.includes(keyword))){
            this.tagify.removeAllTags(); // Clear existing tags
            this.selectedKeywords = []
            this.tagify.addTags(newVal); // Add new tags from prevTags
          }
        }
      },
      immediate:true,
      deep: true // Required if prevTags is an array of objects and might be mutated
    }
  },
  mounted() {
    // simulate fetchign async data and updating the sugegstions list
    // do not create a new Array, but use the SAME ONE
    this.tagify = new Tagify(this.$el,{
      validate: (tagData) => {
        if (this.module === 'sources') {
          const urlRegex = /^(https?:\/\/(?:www\.)?|www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}(:\d+)?(\/[^\s]*)?$/
          return urlRegex.test(tagData.value);
        }else{
          return true
        }
      }
    });
    this.tagify.whitelist = this.prevTags;
    this.tagify.on("add", (e) => {
      this.selectedKeywords = [];
      e.detail.tagify.value.map((el) => {
        this.selectedKeywords.push(el.value);
        this.$emit("fetchKeywords", this.selectedKeywords);
      });
    });

    this.tagify.on("remove", (e) => {
      this.selectedKeywords=[]
      this.selectedKeywords=e.detail.tagify.value.map(tag=>tag.value)
      this.$emit("fetchKeywords", this.selectedKeywords);
    });

    this.tagify.addTags(this.prevTags)

    this.emitter.on('clearNewsFilters', ()=>{
      this.tagify.removeAllTags()
      this.selectedKeywords = [];
    })

    this.emitter.on('clearInsightsFilters', ()=>{
      this.tagify.removeAllTags()
      this.selectedKeywords = [];
    })
    
    this.emitter.on('clearFilters', ()=>{
      this.tagify.removeAllTags()
      this.selectedKeywords = [];
    })
  },
};
</script>

<style lang="scss">

</style>
