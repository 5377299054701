<template>
  <div class="impact-soursce-filter-container">
    <div class="impact-soursce-filter">
      <div class="impact-soursce-filter__header">
        <h1 class="impact-soursce-filter__header--title">Impact Filters</h1>
      </div>
      <div class="impact-soursce-filter__body">
        <a-row :gutter="[40]">
          <a-col
            :xs="24"
            :sm="12"
            :md="8"
            :lg="7"
            :xl="6"
            class="impact-soursce-filter__body--included-excluded-sources-container"
          >
            <div class="input-field">
              <p class="title">Included Locations</p>
              <all-locations :source="'exclude'"></all-locations>
            </div>
          </a-col>
          <a-col
            :xs="24"
            :sm="12"
            :md="8"
            :lg="7"
            :xl="6"
            class="impact-soursce-filter__body--included-excluded-sources-container"
          >
            <div class="input-field">
              <p class="title">Excluded Locations</p>
              <all-locations :source="'exclude'"></all-locations>
            </div>
          </a-col>
          <a-col 
            :xs="24"
            :sm="12"
            :md="8"
            :lg="8"
            :xl="12"
            class="impact-soursce-filter__body--apply-filter-btn-container"
          >
          <button class="apply-btn">Apply filter</button>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import AllLocations from './AllLocations.vue';

export default {
  components: {
    AllLocations
  },
  data() {
    return {
      activeKey: "pdbNewsSources",
    };
  },
  mounted() {
    this.$store.dispatch("discover/getAllSources");
  },
  methods: {
    applyFilter() {
      console.log("Here");
    },
  },
};
</script>

<style lang="scss">
.impact-soursce-filter-container {
  background-color: #000000bf;
  height: 100vh;
  position: absolute;
  width: 100%;
  .impact-soursce-filter {
    background-color: #f8f8ff;
    &__header {
      padding: 2.4rem 3rem 2.3rem;
      margin-bottom: 0;
      border-bottom: 1px solid #c5c6cc;
      &--title {
        font-size: 2rem;
        font-family: $font-primary-bold;
        margin-bottom: 0;
      }
    }
    &__body {
      padding: 0 3rem;
      &--included-excluded-sources-container {
        position: relative;
        padding: 1.8rem 0;
        .input-field {
          .title {
            font-size: 1.4rem;
            font-family: $font-primary-medium;
            margin-bottom: 1.6rem;
            width: fit-content;
          }
          input {
            border-radius: 5px !important;
            font-size: 1.4rem;
            border: 1px solid #c5c6cc;
            font-family: $font-primary;
            width: 100%;
            height: 4rem;
            padding: 1rem;
            outline: none;
            &::placeholder {
              opacity: 0.4;
            }
          }
        }
        .selected-sources {
          margin: 3rem 0 4rem;
          p {
            font-size: 1.4rem;
            font-family: $font-primary-bold;
            margin-bottom: 1.6rem;
            width: fit-content;
          }
          .select-container {
            overflow-y: auto;
            max-height: 8rem;
            height: 100%;
            .select {
              box-sizing: border-box;
              margin: 0 0.8rem 0.8rem 0;
              border-radius: 100px !important;
              color: #000000d9;
              font-variant: tabular-nums;
              list-style: none;
              font-feature-settings: tnum;
              display: inline-block;
              height: 2.8rem;
              padding: 0.5rem 1rem;
              white-space: nowrap;
              background-color: #d0dbff;
              border: 1px solid #3764fb;
              opacity: 1;
              transition: all 0.3s;
              position: relative;
              padding-right: 3rem;
              overflow: auto;
              p {
                margin-right: 0.5rem;
                margin-bottom: 0;
                font-size: 1.2rem;
                font-family: $font-primary;
                line-height: normal;
              }
              .icon {
                padding: 0.5rem;
                background-color: $color-white;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 0.5rem;
                font-size: 0.8rem;
                transform: rotate(45deg);
                cursor: pointer;
                width: fit-content;
                top: 50%;
                position: absolute;
                transform: translateY(-50%) rotate(45deg);
                right: 0.8rem;
              }
            }
          }
          .select-container::-webkit-scrollbar-track {
            background-color: #ffffff3f;
          }
          .select-container::-webkit-scrollbar {
            width: 4px;
            background-color: rgb(224, 224, 224);
            border-radius: 3rem;
          }
          .select-container::-webkit-scrollbar-thumb {
            background-color: $color-primary;
            border-radius: 3rem;
          }
        }
        &::before {
          content: "";
          position: absolute;
          background-color: #c5c6cc;
          height: 100%;
          width: 1px;
          right: 0;
          top: 0;
        }
      }
      &--apply-filter-btn-container {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        .apply-btn {
          border: none;
          outline: none;
          background-color: $color-primary;
          font-size: 1.6rem;
          color: $color-white;
          width: max-content;
          padding: 0 4rem;
          height: 3.9rem;
          font-family: $font-primary-medium;
          margin-left: auto;
          display: block;
          margin-top: auto;
          margin-bottom: 4rem;
          cursor: pointer;
          padding: 0 !important;
        }
      }
    }
  }
}
</style>
